/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PlanName from './PlanName';
import PlanType from './PlanType';

/**
 * The ExtendedPlanOut model module.
 * @module model/ExtendedPlanOut
 * @version 1.0.0
 */
class ExtendedPlanOut {
    /**
     * Constructs a new <code>ExtendedPlanOut</code>.
     * @alias module:model/ExtendedPlanOut
     * @param id {String} 
     * @param type {module:model/PlanType} 
     * @param name {module:model/PlanName} 
     * @param inferenceQuota {Number} 
     * @param storageQuota {Number} 
     * @param dataPointsQuota {Number} 
     * @param maxSeatsQuota {Number} 
     * @param computeQuota {Number} 
     * @param visualSearch {Boolean} 
     * @param executionRemote {Boolean} 
     * @param privateObjectStorage {Boolean} 
     * @param annotationCampaign {Boolean} 
     * @param videoUpload {Boolean} 
     * @param videoTrackingEnabled {Boolean} 
     * @param smartToolsEnabled {Boolean} 
     * @param jiraUrl {String} 
     * @param billingDate {Date} 
     */
    constructor(id, type, name, inferenceQuota, storageQuota, dataPointsQuota, maxSeatsQuota, computeQuota, visualSearch, executionRemote, privateObjectStorage, annotationCampaign, videoUpload, videoTrackingEnabled, smartToolsEnabled, jiraUrl, billingDate) { 
        
        ExtendedPlanOut.initialize(this, id, type, name, inferenceQuota, storageQuota, dataPointsQuota, maxSeatsQuota, computeQuota, visualSearch, executionRemote, privateObjectStorage, annotationCampaign, videoUpload, videoTrackingEnabled, smartToolsEnabled, jiraUrl, billingDate);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, type, name, inferenceQuota, storageQuota, dataPointsQuota, maxSeatsQuota, computeQuota, visualSearch, executionRemote, privateObjectStorage, annotationCampaign, videoUpload, videoTrackingEnabled, smartToolsEnabled, jiraUrl, billingDate) { 
        obj['id'] = id;
        obj['type'] = type;
        obj['name'] = name;
        obj['inference_quota'] = inferenceQuota;
        obj['storage_quota'] = storageQuota;
        obj['data_points_quota'] = dataPointsQuota;
        obj['max_seats_quota'] = maxSeatsQuota;
        obj['compute_quota'] = computeQuota;
        obj['visual_search'] = visualSearch;
        obj['execution_remote'] = executionRemote;
        obj['private_object_storage'] = privateObjectStorage;
        obj['annotation_campaign'] = annotationCampaign;
        obj['video_upload'] = videoUpload;
        obj['video_tracking_enabled'] = videoTrackingEnabled;
        obj['smart_tools_enabled'] = smartToolsEnabled;
        obj['jira_url'] = jiraUrl;
        obj['billing_date'] = billingDate;
    }

    /**
     * Constructs a <code>ExtendedPlanOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedPlanOut} obj Optional instance to populate.
     * @return {module:model/ExtendedPlanOut} The populated <code>ExtendedPlanOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedPlanOut();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = PlanType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = PlanName.constructFromObject(data['name']);
            }
            if (data.hasOwnProperty('inference_quota')) {
                obj['inference_quota'] = ApiClient.convertToType(data['inference_quota'], 'Number');
            }
            if (data.hasOwnProperty('storage_quota')) {
                obj['storage_quota'] = ApiClient.convertToType(data['storage_quota'], 'Number');
            }
            if (data.hasOwnProperty('data_points_quota')) {
                obj['data_points_quota'] = ApiClient.convertToType(data['data_points_quota'], 'Number');
            }
            if (data.hasOwnProperty('max_seats_quota')) {
                obj['max_seats_quota'] = ApiClient.convertToType(data['max_seats_quota'], 'Number');
            }
            if (data.hasOwnProperty('compute_quota')) {
                obj['compute_quota'] = ApiClient.convertToType(data['compute_quota'], 'Number');
            }
            if (data.hasOwnProperty('visual_search')) {
                obj['visual_search'] = ApiClient.convertToType(data['visual_search'], 'Boolean');
            }
            if (data.hasOwnProperty('execution_remote')) {
                obj['execution_remote'] = ApiClient.convertToType(data['execution_remote'], 'Boolean');
            }
            if (data.hasOwnProperty('private_object_storage')) {
                obj['private_object_storage'] = ApiClient.convertToType(data['private_object_storage'], 'Boolean');
            }
            if (data.hasOwnProperty('annotation_campaign')) {
                obj['annotation_campaign'] = ApiClient.convertToType(data['annotation_campaign'], 'Boolean');
            }
            if (data.hasOwnProperty('video_upload')) {
                obj['video_upload'] = ApiClient.convertToType(data['video_upload'], 'Boolean');
            }
            if (data.hasOwnProperty('video_tracking_enabled')) {
                obj['video_tracking_enabled'] = ApiClient.convertToType(data['video_tracking_enabled'], 'Boolean');
            }
            if (data.hasOwnProperty('smart_tools_enabled')) {
                obj['smart_tools_enabled'] = ApiClient.convertToType(data['smart_tools_enabled'], 'Boolean');
            }
            if (data.hasOwnProperty('jira_url')) {
                obj['jira_url'] = ApiClient.convertToType(data['jira_url'], 'String');
            }
            if (data.hasOwnProperty('billing_date')) {
                obj['billing_date'] = ApiClient.convertToType(data['billing_date'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedPlanOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedPlanOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedPlanOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['jira_url'] && !(typeof data['jira_url'] === 'string' || data['jira_url'] instanceof String)) {
            throw new Error("Expected the field `jira_url` to be a primitive type in the JSON string but got " + data['jira_url']);
        }

        return true;
    }


}

ExtendedPlanOut.RequiredProperties = ["id", "type", "name", "inference_quota", "storage_quota", "data_points_quota", "max_seats_quota", "compute_quota", "visual_search", "execution_remote", "private_object_storage", "annotation_campaign", "video_upload", "video_tracking_enabled", "smart_tools_enabled", "jira_url", "billing_date"];

/**
 * @member {String} id
 */
ExtendedPlanOut.prototype['id'] = undefined;

/**
 * @member {module:model/PlanType} type
 */
ExtendedPlanOut.prototype['type'] = undefined;

/**
 * @member {module:model/PlanName} name
 */
ExtendedPlanOut.prototype['name'] = undefined;

/**
 * @member {Number} inference_quota
 */
ExtendedPlanOut.prototype['inference_quota'] = undefined;

/**
 * @member {Number} storage_quota
 */
ExtendedPlanOut.prototype['storage_quota'] = undefined;

/**
 * @member {Number} data_points_quota
 */
ExtendedPlanOut.prototype['data_points_quota'] = undefined;

/**
 * @member {Number} max_seats_quota
 */
ExtendedPlanOut.prototype['max_seats_quota'] = undefined;

/**
 * @member {Number} compute_quota
 */
ExtendedPlanOut.prototype['compute_quota'] = undefined;

/**
 * @member {Boolean} visual_search
 */
ExtendedPlanOut.prototype['visual_search'] = undefined;

/**
 * @member {Boolean} execution_remote
 */
ExtendedPlanOut.prototype['execution_remote'] = undefined;

/**
 * @member {Boolean} private_object_storage
 */
ExtendedPlanOut.prototype['private_object_storage'] = undefined;

/**
 * @member {Boolean} annotation_campaign
 */
ExtendedPlanOut.prototype['annotation_campaign'] = undefined;

/**
 * @member {Boolean} video_upload
 */
ExtendedPlanOut.prototype['video_upload'] = undefined;

/**
 * @member {Boolean} video_tracking_enabled
 */
ExtendedPlanOut.prototype['video_tracking_enabled'] = undefined;

/**
 * @member {Boolean} smart_tools_enabled
 */
ExtendedPlanOut.prototype['smart_tools_enabled'] = undefined;

/**
 * @member {String} jira_url
 */
ExtendedPlanOut.prototype['jira_url'] = undefined;

/**
 * @member {Date} billing_date
 */
ExtendedPlanOut.prototype['billing_date'] = undefined;






export default ExtendedPlanOut;

