/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class PlanName.
* @enum {}
* @readonly
*/
export default class PlanName {
    
        /**
         * value: "FREE"
         * @const
         */
        "FREE" = "FREE";

    
        /**
         * value: "STANDARD"
         * @const
         */
        "STANDARD" = "STANDARD";

    
        /**
         * value: "BUSINESS"
         * @const
         */
        "BUSINESS" = "BUSINESS";

    
        /**
         * value: "ENTERPRISE"
         * @const
         */
        "ENTERPRISE" = "ENTERPRISE";

    

    /**
    * Returns a <code>PlanName</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/PlanName} The enum <code>PlanName</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

