/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PlanDPUOut from './PlanDPUOut';
import PlanMPUOut from './PlanMPUOut';

/**
 * The PlanUsageOut model module.
 * @module model/PlanUsageOut
 * @version 1.0.0
 */
class PlanUsageOut {
    /**
     * Constructs a new <code>PlanUsageOut</code>.
     * @alias module:model/PlanUsageOut
     * @param dpu {module:model/PlanDPUOut} 
     * @param mpu {module:model/PlanMPUOut} 
     */
    constructor(dpu, mpu) { 
        
        PlanUsageOut.initialize(this, dpu, mpu);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, dpu, mpu) { 
        obj['dpu'] = dpu;
        obj['mpu'] = mpu;
    }

    /**
     * Constructs a <code>PlanUsageOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PlanUsageOut} obj Optional instance to populate.
     * @return {module:model/PlanUsageOut} The populated <code>PlanUsageOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PlanUsageOut();

            if (data.hasOwnProperty('dpu')) {
                obj['dpu'] = PlanDPUOut.constructFromObject(data['dpu']);
            }
            if (data.hasOwnProperty('mpu')) {
                obj['mpu'] = PlanMPUOut.constructFromObject(data['mpu']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PlanUsageOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PlanUsageOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of PlanUsageOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `dpu`
        if (data['dpu']) { // data not null
          PlanDPUOut.validateJSON(data['dpu']);
        }
        // validate the optional field `mpu`
        if (data['mpu']) { // data not null
          PlanMPUOut.validateJSON(data['mpu']);
        }

        return true;
    }


}

PlanUsageOut.RequiredProperties = ["dpu", "mpu"];

/**
 * @member {module:model/PlanDPUOut} dpu
 */
PlanUsageOut.prototype['dpu'] = undefined;

/**
 * @member {module:model/PlanMPUOut} mpu
 */
PlanUsageOut.prototype['mpu'] = undefined;






export default PlanUsageOut;

