/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ExtendedPlanOut from '../model/ExtendedPlanOut';
import PlanUsageOut from '../model/PlanUsageOut';

/**
* Payment service.
* @module api/PaymentApi
* @version 1.0.0
*/
export default class PaymentApi {

    /**
    * Constructs a new PaymentApi. 
    * @alias module:api/PaymentApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedPlanOut} and HTTP response
     */
    getOrganizationPlanWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOrganizationPlan");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedPlanOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/plan', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedPlanOut}
     */
    getOrganizationPlan(id) {
      return this.getOrganizationPlanWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PlanUsageOut} and HTTP response
     */
    getOrganizationUsageWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOrganizationUsage");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PlanUsageOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/plan/usage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PlanUsageOut}
     */
    getOrganizationUsage(id) {
      return this.getOrganizationUsageWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
