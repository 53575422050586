/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PlanMPUOut model module.
 * @module model/PlanMPUOut
 * @version 1.0.0
 */
class PlanMPUOut {
    /**
     * Constructs a new <code>PlanMPUOut</code>.
     * @alias module:model/PlanMPUOut
     */
    constructor() { 
        
        PlanMPUOut.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PlanMPUOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PlanMPUOut} obj Optional instance to populate.
     * @return {module:model/PlanMPUOut} The populated <code>PlanMPUOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PlanMPUOut();

            if (data.hasOwnProperty('process_dataset_version')) {
                obj['process_dataset_version'] = ApiClient.convertToType(data['process_dataset_version'], 'Number');
            }
            if (data.hasOwnProperty('launch_training')) {
                obj['launch_training'] = ApiClient.convertToType(data['launch_training'], 'Number');
            }
            if (data.hasOwnProperty('launch_scan')) {
                obj['launch_scan'] = ApiClient.convertToType(data['launch_scan'], 'Number');
            }
            if (data.hasOwnProperty('continuous_training')) {
                obj['continuous_training'] = ApiClient.convertToType(data['continuous_training'], 'Number');
            }
            if (data.hasOwnProperty('deployments')) {
                obj['deployments'] = ApiClient.convertToType(data['deployments'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PlanMPUOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PlanMPUOut</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * @member {Number} process_dataset_version
 * @default 0
 */
PlanMPUOut.prototype['process_dataset_version'] = 0;

/**
 * @member {Number} launch_training
 * @default 0
 */
PlanMPUOut.prototype['launch_training'] = 0;

/**
 * @member {Number} launch_scan
 * @default 0
 */
PlanMPUOut.prototype['launch_scan'] = 0;

/**
 * @member {Number} continuous_training
 * @default 0
 */
PlanMPUOut.prototype['continuous_training'] = 0;

/**
 * @member {Number} deployments
 * @default 0
 */
PlanMPUOut.prototype['deployments'] = 0;






export default PlanMPUOut;

